<template>
  <div class="tw-flex tw-items-center tw-gap-2">
    <reservation-actions-show :item="props.item" />
    <reservation-actions-delete :item="props.item" />

  </div>
</template>

<script setup>
import ReservationActionsShow from './ReservationActionsShow.vue';
import ReservationActionsDelete from './ReservationActionsDelete.vue';
import { defineProps } from 'vue';

const props = defineProps({
    item: Object
});
</script>

<style>

</style>