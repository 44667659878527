<template>
  <v-layout v-if="authStore.isLoggedIn" :full-height="true" class="rounded rounded-md">
    
    <dashboard-sidebar v-model:drawer="drawer" />

    <dashboard-header v-model:drawer="drawer" />

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-layout>
</template>

<script setup>
import DashboardSidebar from './partials/DashboardSidebar.vue';
import DashboardHeader from './partials/DashboardHeader.vue';
import { check } from '@/services/auth/check';
import useAuthStore from '@/stores/authStore';
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import Reservation from '@/api/admin/Reservation';
import { useReservationStore } from '@/stores/reservationStore';

const drawer = ref(true);
const authStore = useAuthStore();
const reservationStore = useReservationStore();
const router = useRouter();

watch(() => authStore.isLoggedIn, (v) => {
  if(!v) {
    router.push({ name: 'login' });
  }
});

const getReservationsCount = async () => {
  reservationStore.setCount(0, true);
  await Reservation.count()
  .then(
    res => {
      if(res.data.status == 200) {
        reservationStore.setCount(res.data.count, false);
      }
    },
    err => {
      console.log(err);
    }
  );
}

check();
getReservationsCount();

</script>

<style>

</style>