<template>
  <div class="tw-h-screen tw-w-full tw-flex tw-items-center tw-justify-center">
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>