import { defineStore } from "pinia";

export const useCarStore = defineStore('carStore', {
    state: () => ({
        cars: []
    }),

    actions: {
        setCars(cars) {
            this.cars = cars;
        },

        addCar(car) {
            this.cars.push(car)
        },

        deleteCar(id) {
            this.cars = this.cars.filter(car => car.id != id);
        },

        updateCar(id, car) {
            this.cars = this.cars.map(c => c.id == id ? car : c);
        }
    }
})