import { defineStore } from "pinia";

export const useAdStore = defineStore('adStore', {
    state: () => ({
        ads: []
    }),

    actions: {
        setAds(ads) {
            this.ads = ads;
        },

        addAd(ad) {
            this.ads.push(ad)
        },

        deleteAd(id) {
            this.ads = this.ads.filter(ad => ad.id != id);
        }
    }
})