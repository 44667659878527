<template>
  <div class="tw-p-2 tw-bg-gray-100 !tw-min-h-[calc(100vh-64px)]">

      <div class="tw-p-4">
          <h1 class="tw-font-medium tw-text-xl">Update car</h1>
      </div>

      <div v-if="!fetching" class="tw-bg-white tw-p-4 tw-h-full tw-flex tw-flex-col tw-rounded-md !tw-min-h-[calc(100vh-150px)]">

          <div class="tw-grid tw-gap-4 md:tw-grid-cols-2">

              <div class="tw-space-y-5">
                  <div>
                      <label for="name"
                      class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">Name</label>
                      <input type="text" v-model="form.car.name" id="name"
                      class="tw-bg-gray-50 tw-border tw-border-solid focus:tw-outline-none tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-primary-600 focus:tw-border-primary-600 tw-block tw-w-full tw-p-2.5 dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Car Name" required="" />
                      <span v-if="form.errors.name" class="tw-text-rose-500 tw-text-xs tw-font-semibold">{{ form.errors.name }}</span>
                  </div>

                  <div>
                    <label for="num_chassis"
                    class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">Chassis Num</label>
                    <input type="text" v-model="form.car.num_chassis" id="num_chassis"
                    class="tw-bg-gray-50 tw-border tw-border-solid focus:tw-outline-none tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-primary-600 focus:tw-border-primary-600 tw-block tw-w-full tw-p-2.5 dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Car Chassis Number" required="" />
                    <span v-if="form.errors.num_chassis" class="tw-text-rose-500 tw-text-xs tw-font-semibold">{{ form.errors.num_chassis }}</span>
                </div>

                <div>
                    <label for="num_insurance_policy"
                    class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">Insurance policy Num</label>
                    <input type="text" v-model="form.car.num_insurance_policy" id="num_insurance_policy"
                    class="tw-bg-gray-50 tw-border tw-border-solid focus:tw-outline-none tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-primary-600 focus:tw-border-primary-600 tw-block tw-w-full tw-p-2.5 dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Car Insurance policy Number" required="" />
                    <span v-if="form.errors.num_insurance_policy" class="tw-text-rose-500 tw-text-xs tw-font-semibold">{{ form.errors.num_insurance_policy }}</span>
                </div>
                <div class="tw-grid sm:tw-grid-cols-2 lg:tw-grid-cols-2 tw-gap-5">

                    <div>
                        <label for="date_begin_insurance"
                        class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">Insurance Begin Date</label>
                        <input type="date" v-model="form.car.date_begin_insurance" id="date_begin_insurance"
                        class="tw-bg-gray-50 tw-border tw-border-solid focus:tw-outline-none tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-primary-600 focus:tw-border-primary-600 tw-block tw-w-full tw-p-2.5 dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Car Insurance policy Number" required="" />
                        <span v-if="form.errors.date_begin_insurance" class="tw-text-rose-500 tw-text-xs tw-font-semibold">{{ form.errors.date_begin_insurance }}</span>
                    </div>

                    <div>
                        <label for="date_fin_insurance"
                        class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">Insurance Final Date</label>
                        <input type="date" v-model="form.car.date_fin_insurance" id="date_fin_insurance"
                        class="tw-bg-gray-50 tw-border tw-border-solid focus:tw-outline-none tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-primary-600 focus:tw-border-primary-600 tw-block tw-w-full tw-p-2.5 dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Car Insurance policy Number" required="" />
                        <span v-if="form.errors.date_fin_insurance" class="tw-text-rose-500 tw-text-xs tw-font-semibold">{{ form.errors.date_fin_insurance }}</span>
                    </div>
                </div>
                <div>
                    <label for="matricule"
                    class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">Matricule</label>
                    <input type="text" v-model="form.car.matricule" id="matricule"
                    class="tw-bg-gray-50 tw-border tw-border-solid focus:tw-outline-none tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-primary-600 focus:tw-border-primary-600 tw-block tw-w-full tw-p-2.5 dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Car Matricule" required="" />
                    <span v-if="form.errors.matricule" class="tw-text-rose-500 tw-text-xs tw-font-semibold">{{ form.errors.matricule }}</span>
                </div>

                  <div>
                      <label for="reviews"
                          class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">Reviews</label>
                      <input type="number" v-model="form.car.reviews" id="reviews"
                          class="tw-bg-gray-50 tw-border tw-border-solid focus:tw-outline-none tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-primary-600 focus:tw-border-primary-600 tw-block tw-w-full tw-p-2.5 dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="Car reviews" required="" />
                      <span v-if="form.errors.reviews" class="tw-text-rose-500 tw-text-xs tw-font-semibold">{{ form.errors.reviews }}</span>
                  </div>

                  <div class="tw-grid sm:tw-grid-cols-2 lg:tw-grid-cols-2 tw-gap-5">
                      <div>
                          <label for="features-transmission"
                              class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">Transmission</label>
                              <div class="tw-relative">
                                  <select  v-model="form.features.transmission" id="features-transmission"
                                  class="tw-bg-gray-50 tw-border tw-border-solid focus:tw-outline-none tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-primary-600 focus:tw-border-primary-600 tw-block tw-w-full tw-p-2.5 dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                  placeholder="Car Transmission">
                                      <option value="manual">Manual</option>
                                      <option value="automatic">Automatic</option>
                                  </select>

                                  <icon icon="fluent:transmission-24-regular" class="tw-text-gray-600 tw-text-2xl tw-absolute tw-right-3 tw-top-1/2 -tw-translate-y-1/2" />
                              </div>
                          <span v-if="form.errors.features?.transmission" class="tw-text-rose-500 tw-text-xs tw-font-semibold">{{ form.errors.features?.transmission }}</span>
                      </div>

                      <div>
                          <label for="features-fuel"
                              class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">Fuel</label>
                              <div class="tw-relative">
                                  <select v-model="form.features.fuel" id="features-fuel"
                                      class="tw-bg-gray-50 tw-border tw-border-solid focus:tw-outline-none tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-primary-600 focus:tw-border-primary-600 tw-block tw-w-full tw-p-2.5 dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                      placeholder="Car fuel">
                                      <option value="gazoline">Essence</option>
                                      <option value="diesel">Diesel</option>
                                  </select>

                                  <icon icon="mdi:petrol-pump" class="tw-text-gray-600 tw-text-2xl tw-absolute tw-right-3 tw-top-1/2 -tw-translate-y-1/2" />
                              </div>
                          <span v-if="form.errors.features?.fuel" class="tw-text-rose-500 tw-text-xs tw-font-semibold">{{ form.errors.features?.fuel }}</span>
                      </div>

                      <div>
                          <label for="features-seats"
                              class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">Seats</label>
                              <div class="tw-relative">
                                  <input type="number" v-model="form.features.seats" id="features-seats"
                                      class="tw-bg-gray-50 tw-border tw-border-solid focus:tw-outline-none tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-primary-600 focus:tw-border-primary-600 tw-block tw-w-full tw-p-2.5 dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                      placeholder="Seats number" />

                                  <icon icon="mdi:car-seat" class="tw-text-gray-600 tw-text-2xl tw-absolute tw-right-3 tw-top-1/2 -tw-translate-y-1/2" />
                              </div>
                          <span v-if="form.errors.features?.seats" class="tw-text-rose-500 tw-text-xs tw-font-semibold">{{ form.errors.features?.seats }}</span>
                      </div>

                      <div>
                          <label for="features-model"
                              class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">Model</label>
                              <div class="tw-relative">
                                  <input type="number" v-model="form.features.model" id="features-model"
                                      class="tw-bg-gray-50 tw-border tw-border-solid focus:tw-outline-none tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-primary-600 focus:tw-border-primary-600 tw-block tw-w-full tw-p-2.5 dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                      placeholder="Model year" />

                                  <icon icon="mdi:engine" class="tw-text-gray-600 tw-text-2xl tw-absolute tw-right-3 tw-top-1/2 -tw-translate-y-1/2" />
                              </div>
                          <span v-if="form.errors.features?.model" class="tw-text-rose-500 tw-text-xs tw-font-semibold">{{ form.errors.features?.model }}</span>
                      </div>

                  </div>
              </div>
              
              <div>
                <CarVariations v-model:car="form.car" />
            </div>

          </div>
          <div class="tw-flex-1"></div>
          <div class="md:tw-col-2 tw-flex tw-justify-end tw-gap-2 tw-mt-5">
            <button 
                @click="handleUpdate"
                :disabled="loading"
                type="button"
                :class="[loading && 'tw-cursor-not-allowed tw-saturate-50']"
                    class="focus:tw-outline-none tw-relative tw-flex tw-justify-center tw-items-start tw-text-white tw-bg-primary-500 hover:tw-bg-primary-600 tw-duration-200 focus:tw-ring-4 focus:tw-ring-primary-300 tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 dark:focus:tw-ring-primary-900"
                    >
                    <icon v-if="loading" icon="line-md:loading-twotone-loop" class="tw-text-2xl tw-absolute" />
                    <span :class="[loading && 'tw-invisible']">Update</span>
                </button>
          </div>
      </div>
  </div>
</template>

<script setup>
import Car from '@/api/admin/Car';
import useAlert from '@/composables/useAlert';
import { reactive, ref, provide } from 'vue';
import { useRoute } from 'vue-router';
import { useCarStore } from '@/stores/carStore';
import { useRouter } from 'vue-router';
import CarVariations from './partials/CarVariations';

const route = useRoute();
const id = route.params.id;
const router = useRouter();
const store = useCarStore();
const loading = ref(false);
const fetching = ref(false);

const form = reactive({
  car: {
    variations: []
  },
  features: {
          transmission: 'manual',
          fuel: 'gazoline',
          seats: 5,
          model: 2022
  },
  errors: {
      features: {}
  },
  update: true
});

provide('form', form)

const handleUpdate = async () => {
    form.errors = { features: {} };

    // if(!form.car.image) {
    //     useAlert('Image is required', 'warning')
    //     return false;
    // }
    
    if(!form.car.name) {
        form.errors.name = 'Name is required';
        return false;
    }

    const car = {
        ...form.features,
        ...form.car,
    }

    loading.value = true;
    await Car.update(id, car)
    .then(
        res => {
            if(res.data.status == 200) {
                useAlert('Car created successfully');
                store.updateCar(id, res.data.car);
                router.push({ name: 'cars' })
            }
        },
        err => {
            if(err?.response?.status == 422) {
                const errors = err?.response?.data?.errors;
                for(let e in errors) {
                    form.errors[e] = errors[e][0];
                }
            }
        }
    )
    loading.value = false;
}


const getCar = async () => {
  fetching.value = true;
  await Car.get(id)
  .then(
    res => {
      if(res.data.status == 200) {
        console.log(res.data)
        form.car = res.data.car;
        form.features = res.data.car;
      }
    }
  );

  fetching.value = false;
}

getCar();

</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

/* Firefox */
input[type=number] {
-moz-appearance: textfield;
}

</style>