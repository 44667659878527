<template>
  <div class="tw-mt-2">
    <div v-if="!form.car.variations.length" class="tw-p-5 tw-bg-gray-50 tw-border tw-border-solid tw-border-gray-200 tw-rounded-md" >
        <p class="tw-text-center tw-text-gray-600">No colors available</p>
    </div>

    <div v-else>
        <div class="tw-grid tw-grid-cols-12 tw-gap-2">
            <div v-for="v in form.car.variations" :key="v.id"  class="2xl:tw-col-span-3 md:tw-col-span-4 tw-pt-8 tw-col-span-6 tw-relative tw-bg-gray-100 tw-rounded-md tw-border tw-border-solid tw-border-gray-200 tw-overflow-hidden tw-aspect-square">
                <img ref="imageRefs" :src="$backend(v.image)" class="tw-w-full tw-h-full tw-object-contain">
                <div class="tw-absolute tw-top-0 tw-w-full tw-bg-white tw-border-b tw-border-gray-200 tw-flex tw-justify-between tw-items-center tw-p-1">
                    <div :style="{background: v.color }" class=" tw-top-2 tw-left-2 tw-w-[20px] tw-h-[20px] tw-rounded-full tw-border tw-border-solid tw-border-gray-200"></div>
                    <button @click="togglePopup(v.id)" class=" tw-right-2 tw-top-2 tw-p-1 tw-rounded tw-bg-white tw-border tw-border-solid tw-border-gray-200 tw-text-red-400"><icon icon="fluent:delete-20-filled" /></button>
                </div>
            </div>
        </div>
    </div>

    <popup :visible="visible" @cancel="visible = false">
        <div
            class="tw-relative tw-max-w-[450px] tw-w-full tw-bg-white tw-overflow-hidden tw-rounded-md tw-mx-auto tw-border tw-border-solid tw-border-gray-400">
            <h1 class="tw-text-lg tw-font-semibold tw-p-5 tw-bg-gray-100">Are you sure you want to delete this color?</h1>

            <div class="tw-flex tw-justify-end tw-items-center tw-col-span-12 tw-gap-2 tw-p-5 ">
                <button @click="visible = false" type="button"
                    class="tw-border-solid tw-duration-200 tw-text-gray-900 tw-bg-white tw-border tw-border-gray-300 focus:tw-outline-none hover:tw-bg-gray-100 focus:tw-ring-4 focus:tw-ring-gray-200 tw-font-medium tw-rounded-lg tw-text-sm tw-px-8 tw-w-fit tw-h-fit tw-py-2.5 dark:tw-bg-gray-800 dark:tw-text-white dark:tw-border-gray-600 dark:hover:tw-bg-gray-700 dark:hover:tw-border-gray-600 dark:focus:tw-ring-gray-700">
                    Cancel
                </button>
                <button @click="handleDelete" type="button" 
                    class="tw-relative tw-flex tw-gap-2 tw-items-center tw-justify-center tw-dura tw-text-white tw-bg-red-500 hover:tw-bg-red-600 tw-shadow-md hover:tw-shadow-lg tw-duration-200 focus:tw-ring-4 focus:tw-ring-red-300 tw-font-medium tw-rounded-md tw-text-sm tw-px-8 tw-w-fit tw-py-2.5 dark:tw-bg-red-600 dark:hover:tw-bg-red-700 focus:tw-outline-none dark:focus:tw-ring-red-800">
                    <span>Delete</span>
                </button>
            </div>
        </div>
    </popup>

  </div>
</template>

<script setup>
import { inject, ref, watch } from 'vue';

const form = inject('form');
const visible = ref(false);
const toDelete = ref(null);
const imageRefs = ref([]);

const togglePopup = id => {
    toDelete.value = id;
    visible.value = true;
}

const handleDelete = () => {
    form.car.variations = form.car.variations.filter(i => i.id != toDelete.value);
    visible.value = false;
}

watch(() => imageRefs, () => {
    form.car.variations.forEach(
        (i, index) => {
            if(i.isImageLoaded || !!i.created_at) return;

            const reader = new FileReader();
            reader.addEventListener('load', function() {
                imageRefs.value[index].src = this.result;
                i.isImageLoaded = true;
            });

            reader.readAsDataURL(i.image);
        }
    )
}, { deep: true });

</script>