<template>
  <div>
    <button @click="visible = true" class="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-rounded-md tw-py-1 tw-px-2 tw-border tw-border-solid tw-border-gray-300">
        <icon icon="gg:details-more" class="tw-text-primary-500 tw-text-lg" />
        <span class="tw-text-sm">Details</span>
    </button>

    <popup :visible="visible" @cancel="cancel">
        <div class="tw-mx-auto tw-p-4 tw-w-full">
            <div class="tw-relative tw-max-w-[500px] tw-mx-auto tw-overflow-hidden  tw-w-full tw-bg-white tw-rounded-md  tw-border tw-border-solid tw-border-gray-400">
                <div class="tw-flex tw-items-center tw-justify-between tw-w-full tw-bg-gray-100 tw-px-5 tw-pr-2 tw-py-2 tw-border-b tw-border-solid tw-border-gray-200">
                    <h1 class="tw-text-lg tw-font-medium tw-text-gray-600">Details</h1>

                    <button @click="cancel" class="tw-p-1 tw-h-7 tw-w-7 tw-flex tw-items-center tw-justify-center tw-border tw-border-solid tw-border-gray-300 tw-rounded-md hover:tw-bg-white tw-duration-200">
                        <icon icon="ic:round-close" class="tw-text-gray-500 tw-text-lg" />
                    </button>
                </div>

                <div class="tw-bg-white">
                    <div class="tw-p-5 tw-space-y-5 tw-border-b tw-border-solid tw-border-gray-300">
                       <div class="tw-grid md:tw-grid-cols-2 md:tw-gap-5">
                            <div>
                                <label class="tw-block tw-mb-1 tw-text-sm tw-font-medium tw-text-gray-600 dark:tw-text-white">Name</label>
                                <p class="tw-text-gray-800 tw-text-md tw-block tw-w-full tw-font-medium"
                                    >{{ item.name }}</p>
                            </div>
        
                            <div>
                                <label class="tw-block tw-mb-1 tw-text-sm tw-font-medium tw-text-gray-600 dark:tw-text-white">Phone</label>
                                <p class="tw-text-gray-800 tw-text-md tw-block tw-w-full tw-font-medium "
                                    >{{ item.phone }}</p>
                            </div>
                       </div>
    
                        <div>
                            <label class="tw-block tw-mb-1 tw-text-sm tw-font-medium tw-text-gray-600 dark:tw-text-white">Email</label>
                            <p class="tw-text-gray-800 tw-text-md tw-block tw-w-full tw-font-medium "
                                >{{ item.email }}</p>
                        </div>
                    </div>

                    <div class="tw-p-5 tw-space-y-5 tw-border-b tw-border-solid tw-border-gray-300">
                        <div>
                            <div class="tw-text-gray-800 tw-grid tw-grid-cols-2 tw-gap-5 tw-text-md tw-w-full tw-font-medium">
                                <div>
                                    <label class="tw-block tw-mb-1 tw-text-sm tw-font-medium tw-text-gray-600 dark:tw-text-white">Pickup Date</label>
                                    <div class="tw-flex tw-items-center tw-gap-2">
                                        <icon icon="solar:calendar-linear" class="tw-text-md tw-text-gray-700" />
                                        <span>
                                            {{ item.pickup_date?.split(' ')[0] }}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                <label class="tw-block tw-mb-1 tw-text-sm tw-font-medium tw-text-gray-600 dark:tw-text-white">Pickup Time</label>
                                    <div class="tw-flex tw-items-center tw-gap-2">
                                        <icon icon="icon-park-outline:time" class="tw-text-md tw-text-gray-700" />
                                        <span>
                                            {{ item.pickup_date?.split(' ')[1] }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <label class="tw-block tw-mb-1 tw-text-sm tw-font-medium tw-text-gray-600 dark:tw-text-white">Pickup Location</label>
                            <p class="tw-text-gray-800 tw-text-md tw-w-full tw-font-medium tw-flex tw-items-center tw-gap-8">
                                {{ item.pickup_location }}
                            </p>
                        </div>
                    </div>

                    <div class="tw-p-5 tw-space-y-5 tw-border-b tw-border-solid tw-border-gray-300">
                        
                        <div>
                            <div class="tw-text-gray-800 tw-grid tw-grid-cols-2 tw-gap-5 tw-text-md tw-w-full tw-font-medium">
                                <div>
                                    <label class="tw-block tw-mb-1 tw-text-sm tw-font-medium tw-text-gray-600 dark:tw-text-white">Return Date</label>
                                    <div class="tw-flex tw-items-center tw-gap-2">
                                        <icon icon="solar:calendar-linear" class="tw-text-md tw-text-gray-700" />
                                        <span>
                                            {{ item.return_date?.split(' ')[0] }}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                <label class="tw-block tw-mb-1 tw-text-sm tw-font-medium tw-text-gray-600 dark:tw-text-white">Return Time</label>
                                    <div class="tw-flex tw-items-center tw-gap-2">
                                        <icon icon="icon-park-outline:time" class="tw-text-md tw-text-gray-700" />
                                        <span>
                                            {{ item.return_date?.split(' ')[1] }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <label class="tw-block tw-mb-1 tw-text-sm tw-font-medium tw-text-gray-600 dark:tw-text-white">Return Location</label>
                            <p class="tw-text-gray-800 tw-text-md tw-w-full tw-font-medium tw-flex tw-items-center tw-gap-8">
                                {{ item.return_location }}
                            </p>
                        </div>
                        
                    </div>

                    <div class="tw-p-5 tw-space-y-5 tw-border-b tw-border-solid tw-border-gray-300">
                        

                        <div>
                            <label class="tw-block tw-mb-1 tw-text-sm tw-font-medium tw-text-gray-600 dark:tw-text-white">Car</label>
                            <p class="tw-text-gray-800 tw-text-md tw-w-full tw-font-medium tw-flex tw-items-center tw-gap-8">
                                {{ item.car?.name }}
                            </p>
                        </div>

                        <div>
                            <label class="tw-block tw-mb-1 tw-text-sm tw-font-medium tw-text-gray-600 dark:tw-text-white">Duration</label>
                            <p class="tw-text-gray-800 tw-text-md tw-w-full tw-font-medium tw-flex tw-items-center tw-gap-8">
                                {{ days }} {{ days > 1 ? 'Days' : 'Dat' }}
                            </p>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </popup>
  </div>
</template>

<script setup>
import { ref, defineProps, toRef, computed } from 'vue';
import moment from 'moment'

const visible = ref(false);
const props = defineProps({
    item: Object
});

const item = toRef(props, 'item');
const days = computed(() => getDeffDays(item.value));

const getDeffDays = (res) => {
    const start = moment(res.pickup_date, 'YYYY-MM-DD');
    const end = moment(res.return_date, 'YYYY-MM-DD');

    return moment.duration(end.diff(start)).asDays();
}

const cancel = () => {
    visible.value = false;
}

</script>

<style>

</style>