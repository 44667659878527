import { createRouter, createWebHistory } from 'vue-router'
import DashboardLayout from '@/layouts/dashboard/DashboardLayout'

import ReservationListView from '@/views/reservations/ReservationListView'
import SettingsView from '@/views/SettingsView'
import HomeView from '@/views/HomeView.vue'
import TestView from '@/views/TestView.vue'
import LoginView from '@/views/auth/LoginView.vue'
import CarListView from '@/views/cars/CarListView'
import CarCreateView from '@/views/cars/CarCreateView'
import CarUpdateView from '@/views/cars/CarUpdateView'
import AdsListView from '@/views/ads/AdsListView'
import AdCreateView from '@/views/ads/AdCreateView'
import authMiddleware from '@/middlewares/authMiddleware'

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: {name: 'dashboard'}

  },
  {
    path: '/test',
    name: 'test',
    component: TestView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },

  {
    path: '/',
    component: DashboardLayout,
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: HomeView
      },

      {
        name: 'reservations',
        path: 'reservations',
        children: [
          {
            path: '',
            name: 'reservations.index',
            component: ReservationListView
          }
        ]
      },

      {
        path: 'cars',
        children: [
          {
            name: 'cars',
            path: '',
            component: CarListView
          },
          {
            name: 'cars.create',
            path: 'create',
            component: CarCreateView
          },
          {
            name: 'cars.edit',
            path: ':id/edit',
            component: CarUpdateView
          }
        ]
      },
      {
        path: 'ads',
        children: [
          {
            name: 'ads',
            path: '',
            component: AdsListView
          },
          {
            name: 'ads.create',
            path: 'create',
            component: AdCreateView
          },
        ]
      },
      
      {
        name: 'settings',
        path: 'settings',
        component: SettingsView
      }
    ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(authMiddleware);

export default router
