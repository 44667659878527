<template>
  <div>
    <button :disabled="loading" @click="handleChange" :class="[loading && '!tw-opacity-50 tw-duration-200']">
        <span
        :class="[props.item.isActive && '!tw-bg-primary-500']"
        class="tw-block tw-w-[45px] tw-h-[25px] tw-bg-gray-200 tw-rounded-full tw-border tw-p-1 tw-duration-200">
            <span
            :class="[props.item.isActive && 'tw-translate-x-[20px]']"
            class="tw-h-full tw-aspect-square tw-bg-white tw-shadow tw-flex tw-items-center tw-justify-center tw-rounded-full tw-duration-200">
        </span>
        </span>
    </button>
  </div>
</template>

<script setup>
import Car from '@/api/admin/Car';
import useAlert from '@/composables/useAlert';
import { useCarStore } from '@/stores/carStore';
import { defineProps, ref } from 'vue';

const props = defineProps(['item']);
const loading = ref(false);
const store = useCarStore();

const handleChange = async () => {
    loading.value = true;

    await Car.toggleActive(props.item.id, !props.item.isActive)
    .then(
        res => {
            if(res.data.status == 200) {
                console.log(res.data)
                store.updateCar(props.item.id, res.data.car);
            }
        },
        err => {
            console.log(err)
            useAlert('Something went wrong', 'danger');
        }
    );

    loading.value = false
}
</script>

<style>

</style>