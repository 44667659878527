<template>
  <div class="tw-p-2 tw-bg-gray-100 tw-min-h-[calc(100vh-64px)]">

    <div class="tw-bg-white tw-p-4 tw-h-full">
        <div class="tw-flex tw-items-center tw-justify-between tw-mb-5">
            <h1 class="tw-font-medium tw-text-xl">Cars List</h1>

            <div>
                <router-link 
                    :to="{ name: 'cars.create' }"
                    class="focus:tw-outline-none tw-text-white tw-bg-primary-400 hover:tw-bg-primary-500 focus:tw-ring-4 focus:tw-ring-primary-300 tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 dark:focus:tw-ring-primary-900"
                >Create</router-link>
            </div>
        </div>

        <div class="tw-mb-2 tw-w-full md:tw-max-w-[350px] tw-ml-auto">
            <div class="tw-relative">
                <input type="text" v-model="search" id="search"
                    class="tw-bg-gray-50 tw-border tw-pr-8 tw-border-solid focus:tw-outline-none tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-primary-600 focus:tw-border-primary-600 tw-block tw-w-full tw-p-2.5 dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Search" />

                <icon icon="solar:magnifer-linear" class="tw-text-gray-600 tw-text-2xl tw-absolute tw-right-3 tw-top-1/2 -tw-translate-y-1/2" />
            </div>
        </div>

        <datatable :search="search" :columns="cols" :rows="cars" :loading="loading">
            <template #created_at="data">
                <span class="tw-whitespace-nowrap tw-break-keep">{{ !!data.value.created_at && data.value.created_at.split('T')[0] }}</span>
            </template>
            <template #image="data">
                <img :src="$backend(data.value.image)" class="tw-w-[100px] tw-h-[60px] tw-object-contain">
            </template>
            <template #isActive="data">
                <CarActionsActive :item="data.value" :key="data.value.id" />
            </template>
            <template #days="data">
                <div class="tw-flex tw-flex-col tw-relative tw-whitespace-nowrap">
                    <div class="tw-flex tw-items-center tw-gap-1 tw-text-primary-600">
                        <icon icon="icon-park-outline:time" class=" tw-text-md" />
                        <span class="tw-text-lg">
                            {{ getDeffDays(data.value) }}
                        </span>
                    </div>

                </div>
            </template>
            <template #actions="data">
                <CarActions :key="data.value.id" :item="data.value" />
            </template>
        </datatable>
    </div>
  </div>
</template>

<script setup>
import Car from '@/api/admin/Car';
import CarActions from './actions/CarActions'
import CarActionsActive from './actions/CarActionsActive'
import { ref, computed } from 'vue';
import { useCarStore } from '@/stores/carStore';
import moment from 'moment';

const store = useCarStore();
const loading = ref(false);
const search = ref('');
const cars = computed(() => store.cars);
const cols = ref([
    { field: 'id', title: '#', isUnique: true, width: '50px' },
    { field: 'image', title: 'Image', width: '110px' },
    { field: 'num_chassis', title: 'Chassis Num' },
    { field: 'num_insurance_policy', title: 'Insurance Policy Num' },
    { field: 'days', title: 'Insurance Delay' },
    { field: 'matricule', title: 'Matricule' },
    { field: 'isActive', title: 'Active', width: '70px' },
    { field: 'created_at', title: 'Created', width: '120px' },
    { field: 'actions', title: '', width: '200px' },
]);

const getCars = async () => {
    loading.value = true;

    await Car.all()
    .then(
        res => {
            if(res.data.status == 200) {
                store.setCars(res.data.cars);
            }
        }
    );

    loading.value = false;
}

getCars();

const getDeffDays = (res) => {
    const start = moment(res.date_begin_insurance, 'YYYY-MM-DD');
    const end = moment(res.date_fin_insurance, 'YYYY-MM-DD');

    return moment.duration(end.diff(start)).asDays();
}

</script>

<style>

</style>