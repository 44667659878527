<template>
    <popup :visible="props.visible" :closeable="false" @cancel="emit('update:visible', false)">
        <div
            class="tw-relative tw-max-w-[450px] tw-w-full tw-bg-white tw-overflow-hidden tw-rounded-md tw-mx-auto tw-border tw-border-solid tw-border-gray-400">
            <h1
                class="tw-text-lg tw-font-semibold tw-p-3  tw-bg-gray-50 tw-border-b tw-border-solid tw-border-gray-100 tw-text-gray-800">
                Add color</h1>
            <div class="tw-p-5">
                <div>
                    <label for="color"
                        class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">Color</label>
                    <input type="color" v-model="form.variation.color" id="color"
                        class="tw-bg-gray-50 tw-px-2 tw-border tw-border-solid focus:tw-outline-none tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-primary-600 focus:tw-border-primary-600 tw-block tw-w-full  dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Car color" required="" />
                    <span v-if="form.errors.color" class="tw-text-rose-500 tw-text-xs tw-font-semibold">{{ form.errors.color
                    }}</span>
                </div>

                <div class="tw-mt-5">
                    <h1 class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">Upload Image
                    </h1>

                    <div role="button" @click="imageInput.click()" :class="[!!form.variation.image && 'tw-invisible']"
                        class="tw-h-[250px] tw-overflow-hidden tw-relative tw-flex tw-justify-center tw-items-center tw-p-5 tw-w-full tw-bg-gray-100 tw-rounded-md tw-border-dashed tw-border tw-border-gray-400">
                        <div class="tw-text-center">
                            <div class="tw-p-5 tw-bg-white tw-rounded-full tw-w-fit tw-mx-auto tw-mb-3">
                                <icon icon="solar:folder-with-files-linear" class="tw-text-xl" />
                            </div>
                            <h2 class="tw-text-lg">
                                Drag and drop your image here
                            </h2>
                            <h2 class="tw-text-sm">
                                Or <span class="tw-text-primary-500 hover:tw-underline">Click to brose your files</span>
                            </h2>
                        </div>

                        <input type="file" @change="handleImageChange" ref="imageInput" class="tw-hidden">

                        <div v-show="!!form.variation.image"
                            class="tw-absolute tw-visible tw-w-full tw-h-full tw-bg-black/10 tw-rounded-lg tw-overflow-hidden ">
                            <img ref="imageDisplay" class="tw-w-full tw-h-full tw-object-contain">
                        </div>
                    </div>

                </div>
            </div>
            <div class="tw-flex tw-justify-end tw-items-center tw-col-span-12 tw-gap-2 tw-p-3 tw-bg-gray-50">
                <button @click="emit('update:visible', false)" type="button"
                    class="tw-border-solid tw-duration-200 tw-text-gray-900 tw-bg-white tw-border tw-border-gray-300 focus:tw-outline-none hover:tw-bg-gray-100 focus:tw-ring-4 focus:tw-ring-gray-200 tw-font-medium tw-rounded-lg tw-text-sm tw-px-8 tw-w-fit tw-h-fit tw-py-2.5 dark:tw-bg-gray-800 dark:tw-text-white dark:tw-border-gray-600 dark:hover:tw-bg-gray-700 dark:hover:tw-border-gray-600 dark:focus:tw-ring-gray-700">
                    Cancel
                </button>
                <button type="button" @click="handleAdd"
                    class="tw-relative tw-flex tw-gap-2 tw-items-center tw-justify-center tw-dura tw-text-white tw-bg-primary-500 hover:tw-bg-primary-600 tw-shadow-md hover:tw-shadow-lg tw-duration-200 focus:tw-ring-4 focus:tw-ring-primary-300 tw-font-medium tw-rounded-md tw-text-sm tw-px-8 tw-w-fit tw-py-2.5 dark:tw-bg-primary-600 dark:hover:tw-bg-primary-700 focus:tw-outline-none dark:focus:tw-ring-primary-800">

                    <span>Add</span>
                </button>
            </div>
        </div>
    </popup>
</template>

<script setup>
import useAlert from '@/composables/useAlert';
import { defineProps, defineEmits, reactive, ref, inject, watch } from 'vue';

const props = defineProps(['visible']);
const emit = defineEmits(['update:visible']);
const imageInput = ref(null);
const imageDisplay = ref(null);
const form = reactive({
    variation: {
        color: '#000000',
        image: '',
        imageFile: ''
    },
    errors: {}
});
const carForm = inject('form');

const handleImageChange = $e => {
    const files = $e.target.files;
    if(!files.length) return;

    form.variation.image = files[0];
    form.variation.isImageChanged = true;
    const reader = new FileReader();
    reader.addEventListener('load', function() {
        imageDisplay.value.src = this.result;
    });

    reader.readAsDataURL(form.variation.image);
}

const handleAdd = () => {

    if(!form.variation.image) {
        useAlert('Image field is required', 'warning')
        return false;
    }

    const lastItem = carForm.car.variations.length > 0 ? carForm.car.variations[carForm.car.variations.length - 1].id : 1;
    // Get the last ID and add 1 to it
    const newId = lastItem ? lastItem + 1 : 1;

    carForm.car.variations.push({...form.variation, id: newId, isNew: true});
    useAlert('Color added');
    emit('update:visible', false);
}

watch(() => props.visible, () => {
    form.variation.color = '#000000';
    form.variation.image = null;
})
</script>