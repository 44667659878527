import Login from "@/api/auth/Login";
import useAlert from "@/composables/useAlert";
import useAuthStore from '@/stores/authStore';


export const login = (app) => {
    const user = {
        email: app.email,
        password: app.password
    }

    if(!validate(app, user)) return false;
    
    app.loading = true
    Login.login(user)
    .then(data => success(data, app), error => fails(error, app))
    .finally(() => app.loading = false)
}


const success = ({data}, app) => {
    const store = useAuthStore();
    if(data.code != 'SUCCESS') return false;

    const user = data.user;
    const token = data.token;

    store.setIsLoggedIn(true);
    store.setUser(user);
    store.setToken(token);

    app.$router.push({name: 'dashboard'});
    console.log('Logged in');
}


const fails = (error) => {
    console.log(error);
    const res = error?.response

    if([422, 401].includes(res?.status)) {
        useAlert('Email ou mot de passe invalides', 'warning')
    } else {
        useAlert('quelque chose n\'a pas fonctionné', 'warning')
    }
}


const validate = (app, user) => {

    if(!user.email) {
        app.errors.email = 'Email field is required'
        return false;
    }

    if(!user.password) {
        app.errors.password = 'Password field is required'
        return false;
    }

    return true;
}