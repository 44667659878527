import useAuthStore from "@/stores/authStore";

export default (to, from, next) => {
    const authStore = useAuthStore();
    if (!authStore.isLoggedIn && to.name != 'login') {
        return next({ name: 'login' });
    }

    if(authStore.isLoggedIn && to.name == 'login') {
        return next({ name: 'dashboard' })
    }
    return next();
}
