<template>
  <v-navigation-drawer
    :rail="false"
    location="left"
    :absolute="false"
    class="!tw-top-0 !tw-fixed tw-bg-primary-950"
    :touchless="true"
    v-model="drawerValue"
    
  >
    <div class="tw-border-b tw-border-primary-900">
        
        <div class="tw-p-3">
            <img
            class="tw-max-w-[150px] tw-mx-auto"
            :src="$frontend('assets/images/logo.png')"
            />
        </div>

    </div>
      <v-list class="!tw-px-2 tw-space-y-2">
        <v-list-item v-for="link in links" :key="link.id" :to="{name: link.to}" :active="false" :link="true" :ripple="false" 
        :class="[$route.name == link.to && '!tw-bg-white !tw-text-primary-500']"
        class="!tw-overflow-visible !tw-px-2 !tw-py-2 tw-bg-primary-900 tw-text-white !tw-min-h-[35px] tw-rounded-lg">
          <div class="tw-relative tw-z-10  tw-p-0.5  tw-flex tw-flex-row tw-items-center tw-gap-2">

              <icon 
              :class="[$route.name == link.to && '!tw-text-primary-800']"
              class="tw-text-white tw-text-lg" :icon="link.icon" />

            <span 
            :class="[$route.name == link.to && '!tw-text-primary-800 tw-font-medium']"
            class=" tw-text-white tw-text-sm">{{ link.name }}</span>

            <div v-if="link.name == 'Reservations' && !isCountLoading" class="tw-font-semibold tw-text-sm tw-flex tw-items-center tw-justify-center tw-px-2 tw-p-1 tw-h-[20px] tw-rounded-md tw-bg-orange-500 tw-text-white">
              {{ reservationCount }}
            </div>
            <div v-if="link.name == 'Reservations' && isCountLoading" class="tw-font-semibold tw-text-sm tw-flex tw-items-center tw-justify-center tw-px-2 tw-p-1 tw-h-[20px] tw-rounded-md tw-bg-orange-500 tw-text-white">
              <icon icon="line-md:loading-twotone-loop" class="tw-text-white" />
            </div>

          </div>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
</template>

<script setup>
import { useReservationStore } from "@/stores/reservationStore";
import { defineProps, defineEmits, computed } from "vue";
import { useRoute } from "vue-router";

const props = defineProps({
  drawer: {
    required: false,
    default: true,
  },
});
const emit = defineEmits(["update:drawer"]);
const reservationStore = useReservationStore();
const reservationCount = computed(() => reservationStore.count);
const isCountLoading = computed(() => reservationStore.isCountLoading);

const drawerValue = computed({
  get: () => props.drawer,
  set: (v) => emit("update:drawer", v),
});

const links = [
    {
        "id": 1,
        "name": "Dashboard",
        "to": "dashboard",
        "icon": "ic:round-space-dashboard"
    },
    {
        "id": 2,
        "name": "Reservations",
        "to": "reservations.index",
        "icon": "solar:key-bold"
    },
    {
        "id": 3,
        "name": "Cars",
        "to": "cars",
        "icon": "fa6-solid:car-side"
    },
    {
        "id": 4,
        "name": "Ads",
        "to": "ads",
        "icon": "ic:twotone-ads-click"
    },
    {
        "id": 5,
        "name": "Settings",
        "to": "settings",
        "icon": "solar:settings-bold"
    }
];

console.log(useRoute())

</script>

<style>

</style>