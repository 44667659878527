<template>
    <div class="tw-p-2 tw-bg-gray-100 !tw-min-h-[calc(100vh-64px)]">

        <div class="tw-p-4">
            <h1 class="tw-font-medium tw-text-xl">Create an ads</h1>
        </div>

        <div class="tw-bg-white tw-p-4 tw-h-full tw-flex tw-flex-col tw-rounded-md !tw-min-h-[calc(100vh-150px)]">

            <div class="tw-grid tw-gap-4 md:tw-grid-cols-2">

             

                <div>
                    <h1 class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">Upload Image</h1>         

                    <div role="button" @click="imageInput.click()" 
                        :class="[!!form.ad.image && 'tw-invisible' ]"
                        class="tw-h-[300px] tw-overflow-hidden tw-relative tw-flex tw-justify-center tw-items-center tw-p-5 tw-w-full tw-bg-gray-100 tw-rounded-md tw-border-dashed tw-border tw-border-gray-400">
                        <div class="tw-text-center">
                            <div class="tw-p-5 tw-bg-white tw-rounded-full tw-w-fit tw-mx-auto tw-mb-3">
                                <icon icon="solar:folder-with-files-linear" class="tw-text-xl"  />
                            </div>
                            <h2 class="tw-text-lg">
                                Drag and drop your image here
                            </h2>
                            <h2 class="tw-text-sm">
                                Or <span class="tw-text-primary-500 hover:tw-underline">Click to brose your files</span>
                            </h2>
                        </div>

                        <input type="file" @change="handleImageChange" ref="imageInput" class="tw-hidden">

                        <div v-show="!!form.ad.image" class="tw-absolute tw-visible tw-w-full tw-h-full tw-bg-black/10 tw-rounded-lg tw-overflow-hidden ">
                            <img 
                                ref="imageDisplay" 
                            class="tw-w-full tw-h-full tw-object-contain">
                        </div>
                    </div>

                </div>

            </div>
            <div class="tw-flex-1"></div>
            <div class="md:tw-col-2 tw-flex tw-justify-end tw-gap-2 tw-mt-5">
                <button 
                @click="handleCreate"
                :disabled="loading"
                type="button"
                :class="[loading && 'tw-cursor-not-allowed tw-saturate-50']"
                    class="focus:tw-outline-none tw-relative tw-flex tw-justify-center tw-items-start tw-text-white tw-bg-primary-500 hover:tw-bg-primary-600 tw-duration-200 focus:tw-ring-4 focus:tw-ring-primary-300 tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 dark:focus:tw-ring-primary-900"
                    >
                    <icon v-if="loading" icon="line-md:loading-twotone-loop" class="tw-text-2xl tw-absolute" />
                    <span :class="[loading && 'tw-invisible']">Create</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import Ad from '@/api/admin/Ad';
import useAlert from '@/composables/useAlert';
import { useAdStore } from '@/stores/adStore';
import { reactive, ref, provide } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const store = useAdStore();
const loading = ref(false);
const imageInput = ref(null);
const imageDisplay = ref(null);

const form = reactive({
    ad: {
    },
    errors: {
    }
});

provide('form', form)

const handleCreate = async () => {
    // reset errors
    form.errors = { features: {} };

    if(!form.ad.image) {
        useAlert('Image is required', 'warning')
        return false;
    }

    const ad = form.ad

    loading.value = true;
    await Ad.create(ad)
    .then(
        res => {
            if(res.data.status == 200) {
                useAlert('Ad created successfully');
                store.addAd(res.data.ad);
                router.push({ name: 'ads' })
            }
        },
        err => {
            if(err?.response?.status == 422) {
                const errors = err?.response?.data?.errors;
                for(let e in errors) {
                    form.errors[e] = errors[e][0];
                }
            }
        }
    )
    loading.value = false;
}

const handleImageChange = $e => {
    const files = $e.target.files;
    if(!files.length) return;

    const imageFile = files[0];
    const reader = new FileReader();
    reader.addEventListener('load', function() {
        imageDisplay.value.src = this.result;
        form.ad.image = imageFile;
    });

    reader.readAsDataURL(imageFile);

}

</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>