import {
    defineStore
} from 'pinia';

const initalState = {
    isLoggedIn: localStorage.getItem('isLoggedIn') ? JSON.parse(localStorage.getItem('isLoggedIn')) : false,
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
    token: localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null,
}

export default defineStore('authStore', {
    state: () => initalState,

    getters: {},

    actions: {
        setIsLoggedIn(value) {
            this.isLoggedIn = value;
            localStorage.setItem('isLoggedIn', JSON.stringify(value));
        },

        setUser(value) {
            this.user = value;
            localStorage.setItem('user', JSON.stringify(value));
        },

        setToken(value) {
            this.token = value;
            localStorage.setItem('token', JSON.stringify(value));
        },

        logout() {
            this.setIsLoggedIn(false);
            this.setToken(null);
            this.setUser(null);
        }
    }
});