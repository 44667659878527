import User from "@/api/auth/User";
import useAlert from "@/composables/useAlert";
import useAuthStore from '@/stores/authStore';
import router from '@/router'

export const check = () => {

    User.user()
    .then(data => success(data), error => fails(error))
}


const success = ({data}) => {
    const store = useAuthStore();
    if(data.code != 'SUCCESS') return false;

    const user = data.user;

    store.setIsLoggedIn(true);
    store.setUser(user);

}


const fails = (error) => {
    const store = useAuthStore();
    console.log(error)
    useAlert('Session Expired !', 'danger');
    store.setIsLoggedIn(false);
    store.setUser(null);
    router.push({name: 'login'})

}
