<template>
  <div class="tw-p-5">
    <h1 class="tw-text-xl tw-font-bold tw-text-primary-900 tw-mb-5">Dashboard</h1>

    <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4">
      <div class="tw-p-6 tw-bg-primary-500 tw-rounded-lg tw-shadow-md">
        <h2 class="tw-text-white">Card 1</h2>
        <!-- Insert analytics here -->
      </div>
      <div class="tw-p-6 tw-bg-primary-500 tw-rounded-lg tw-shadow-md">
        <h2 class="tw-text-white">Card 2</h2>
        <!-- Insert analytics here -->
      </div>
      <div class="tw-p-6 tw-bg-primary-500 tw-rounded-lg tw-shadow-md">
        <h2 class="tw-text-white">Card 3</h2>
        <!-- Insert analytics here -->
      </div>
      <div class="tw-p-6 tw-bg-primary-500 tw-rounded-lg tw-shadow-md">
        <h2 class="tw-text-white">Card 4</h2>
        <!-- Insert analytics here -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'HomeView',

  components: {},
});
</script>
