<template>
  <div>
    <button @click="visible = true" class="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-rounded-md tw-py-1 tw-px-2 tw-border tw-border-solid tw-border-gray-300">
        <icon icon="ph:trash-fill" class="tw-text-red-500 tw-text-lg" />
    </button>

    <popup :closeable="!loading" :visible="visible" @cancel="visible = false">
            <div
                class="tw-relative tw-max-w-[450px] tw-w-full tw-bg-white tw-overflow-hidden tw-rounded-md tw-mx-auto tw-border tw-border-solid tw-border-gray-400">
                <h1 class="tw-text-lg tw-font-semibold tw-p-5 tw-bg-gray-100">Are you sure you want to delete this ?</h1>

                <div class="tw-flex tw-justify-end tw-items-center tw-col-span-12 tw-gap-2 tw-p-5 ">
                    <button @click="visible = false" type="button"
                        class="tw-border-solid tw-duration-200 tw-text-gray-900 tw-bg-white tw-border tw-border-gray-300 focus:tw-outline-none hover:tw-bg-gray-100 focus:tw-ring-4 focus:tw-ring-gray-200 tw-font-medium tw-rounded-lg tw-text-sm tw-px-8 tw-w-fit tw-h-fit tw-py-2.5 dark:tw-bg-gray-800 dark:tw-text-white dark:tw-border-gray-600 dark:hover:tw-bg-gray-700 dark:hover:tw-border-gray-600 dark:focus:tw-ring-gray-700">
                        Cancel
                    </button>
                    <button @click="destroy" type="button" :class="[loading && '!tw-bg-red-400 tw-cursor-not-allowed']"
                        class="tw-relative tw-flex tw-gap-2 tw-items-center tw-justify-center tw-dura tw-text-white tw-bg-red-500 hover:tw-bg-red-600 tw-shadow-md hover:tw-shadow-lg tw-duration-200 focus:tw-ring-4 focus:tw-ring-red-300 tw-font-medium tw-rounded-md tw-text-sm tw-px-8 tw-w-fit tw-py-2.5 dark:tw-bg-red-600 dark:hover:tw-bg-red-700 focus:tw-outline-none dark:focus:tw-ring-red-800">
                        <icon v-if="loading" icon="line-md:loading-twotone-loop" class="tw-absolute tw-text-xl" />

                        <span :class="[loading && '!tw-opacity-0']">Delete</span>
                    </button>
                </div>
            </div>
        </popup>

  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue';
import useAlert from '@/composables/useAlert'
import Reservation from '@/api/admin/Reservation'
import { useReservationStore } from '@/stores/reservationStore';

const props =  defineProps(['item']);
const loading = ref(false);
const visible = ref(false);
const store = useReservationStore();


const destroy = async () => {
    loading.value = true

    await Reservation.delete(props.item.id)
    .then(
        res => {
            if(res.data.status == 200) {
                store.deleteReservation(props.item.id);
                useAlert('Reservation Deleted Successfully');
                visible.value = false;
            }
        },
        err => {
            useAlert('Something went wrong', 'danger');
            console.log(err)
        }
    )
    loading.value = false;
}
</script>

<style>

</style>