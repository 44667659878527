import Api , { ApiForm } from '@/api/Api'
import Csrf from '@/api/Csrf'

export default new class Ad {

    async all() {
        await Csrf.getCookie();
        return Api.get('api/ads');
    }

    async create(ad) {
        await Csrf.getCookie();
        return ApiForm.post('api/ads', ad);
    }
}
