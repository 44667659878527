<template>
  <div>
    <div class="tw-flex tw-items-center tw-justify-between">
      <label class="tw-block tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">Colors</label>
      <button @click="visible = true" class="tw-text-primary-500 tw-underline tw-text-sm">
        Add
      </button>
    </div>
    <div>
      <CarVariationForm v-model:visible="visible"  />
    </div>
    <div>
      <CarVariationDisplay />
    </div>
  </div>
</template>

<script setup>
import {  ref } from 'vue';
import CarVariationForm from './CarVariationForm.vue';
import CarVariationDisplay from './CarVariationDisplay.vue';

const visible = ref(false);

</script>