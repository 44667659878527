import Api from '@/api/Api'
import Csrf from '@/api/Csrf'

export default new class Login {

    async user() {
        await Csrf.getCookie();
        return Api.get('api/auth/user');
    }

}
