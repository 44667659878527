import Api, { ApiForm } from '@/api/Api'
import Csrf from '@/api/Csrf'

export default new class Car {

    async all() {
        await Csrf.getCookie();
        return Api.get('api/cars');
    }

    async get(id) {
        await Csrf.getCookie();
        return Api.get('api/cars/' + id);
    }

    async delete(id) {
        await Csrf.getCookie();
        return Api.delete('api/cars/' + id);
    }

    async create(car) {
        await Csrf.getCookie();
        return ApiForm.post('api/cars', car);
    }

    async update(id, car) {
        await Csrf.getCookie();
        return ApiForm.post('api/cars/' + id, car);
    }

    async toggleActive(id, value) {
        await Csrf.getCookie();
        return ApiForm.post('api/cars/' + id + '/active', {value});
    }

}
