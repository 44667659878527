<template>
    <div class="tw-flex tw-items-center tw-gap-2">
        <router-link :to="{ name: 'cars.edit', params: { id: props.item.id } }" class="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-rounded-md tw-py-1 tw-px-2 tw-border tw-border-solid tw-border-gray-300">
            <icon icon="ph:pencil-simple-fill" class="tw-text-blue-500 tw-text-lg" />
            <span class="tw-text-sm">Edit</span>
        </router-link>

        <car-actions-delete :item="props.item" />
    </div>
</template>

<script setup>
import CarActionsDelete from './CarActionsDelete.vue';
import { defineProps } from 'vue';

const props = defineProps(['item'])
</script>

<style>

</style>