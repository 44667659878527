import Api, { ApiForm } from '@/api/Api'
import Csrf from '@/api/Csrf'

export default new class Reservation {

    async all() {
        await Csrf.getCookie();
        return Api.get('api/reservations');
    }

    async count() {
        await Csrf.getCookie();
        return Api.get('api/reservations/count');
    }

    async get(id) {
        await Csrf.getCookie();
        return Api.get('api/reservations/' + id);
    }

    async delete(id) {
        await Csrf.getCookie();
        return Api.delete('api/reservations/' + id);
    }

    async create(reservation) {
        await Csrf.getCookie();
        return ApiForm.post('api/reservations', reservation);
    }

}
