import { defineStore } from "pinia";

export const useReservationStore = defineStore('reservationStore', {
    state: () => ({
        reservations: [],
        count: 0,
        isCountLoading: true
    }),

    actions: {
        setReservations(reservations) {
            this.reservations = reservations;
        },

        addReservation(reservation) {
            this.reservations.push(reservation)
        },

        deleteReservation(id) {
            this.reservations = this.reservations.filter(car => car.id != id);
        },

        setCount(count, loading = null ) {
            this.count = count;

            if(loading != null) {
                this.isCountLoading = loading;
            }
        }

    }
})