<template>
    <div class="tw-p-2 tw-bg-gray-100 tw-min-h-[calc(100vh-64px)]">

        <div class="tw-bg-white tw-p-4 tw-h-full">
            <div class="tw-flex tw-items-center tw-justify-between tw-mb-5">
                <h1 class="tw-font-medium tw-text-xl">Reservations List</h1>
            </div>

            <div class="tw-mb-2 tw-w-full md:tw-max-w-[350px] tw-ml-auto">
                <div class="tw-relative">
                    <input type="text" v-model="search" id="search"
                        class="tw-bg-gray-50 tw-border tw-pr-8 tw-border-solid focus:tw-outline-none tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-primary-600 focus:tw-border-primary-600 tw-block tw-w-full tw-p-2.5 dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Search" />

                    <icon icon="solar:magnifer-linear" class="tw-text-gray-600 tw-text-2xl tw-absolute tw-right-3 tw-top-1/2 -tw-translate-y-1/2" />
                </div>
            </div>
            
            <datatable :search="search" :columns="cols" :rows="reservations" :loading="loading">

                <template #date="data">
                    <div @click="data.value.showDefault = !data.value.showDefault" class="tw-cursor-pointer tw-flex tw-flex-col tw-relative tw-whitespace-nowrap">
                        <div class="tw-flex tw-items-center tw-gap-1">
                            <icon icon="ic:round-play-arrow" class="tw-text-yellow-500 tw-text-lg" />
                            <span v-if="!data.value.showDefault">
                                {{ moment(data.value.pickup_date).format('DD MMMM YYYY HH:mm') }}
                            </span>
                            <span v-if="data.value.showDefault">
                                {{ data.value.pickup_date }}
                            </span>
                        </div>

                        <div class="tw-flex tw-items-center tw-gap-1">
                            <icon icon="ic:round-play-arrow" class="tw-text-green-500 tw-text-lg" />
                            <span v-if="!data.value.showDefault">
                                {{ moment(data.value.return_date).format('DD MMMM YYYY HH:mm') }}
                            </span>
                            <span v-if="data.value.showDefault">
                                {{ data.value.return_date }}
                            </span>
                        </div>
                    </div>
                </template>

                <template #name="data">
                    <p class="tw-truncate">{{ data.value.name }}</p>
                </template>

                <template #location="data">
                    <div class="tw-flex tw-flex-col tw-relative tw-whitespace-nowrap tw-pl-4">
                        <div class="tw-flex tw-items-center tw-gap-1 tw-truncate">
                            <span>
                                {{ data.value.pickup_location }}
                            </span>
                        </div>

                        <div class="tw-flex tw-items-center tw-gap-1 tw-truncate">
                            <span>
                                {{ data.value.return_location }}
                            </span>
                        </div>
                        
                        <icon icon="lucide:arrow-up-from-dot" class="tw-absolute tw-rotate-180 tw-text-gray-500 tw-text-xl -tw-left-2 tw-top-1/2 -tw-translate-y-1/2" />
                    </div>
                </template>


                <template #days="data">
                    <div class="tw-flex tw-flex-col tw-relative tw-whitespace-nowrap">
                        <div class="tw-flex tw-items-center tw-gap-1 tw-text-primary-600">
                            <icon icon="icon-park-outline:time" class=" tw-text-md" />
                            <span class="tw-text-lg">
                                {{ getDeffDays(data.value) }}
                            </span>
                        </div>

                    </div>
                </template>

                <template #actions="data">
                    <ReservationActions :key="data.value.id" :item="data.value" />
                </template>


            </datatable>
        </div>
    </div>
</template>
<script setup>
import Reservation from '@/api/admin/Reservation'
import ReservationActions from './actions/ReservationActions.vue';
import { useReservationStore } from '@/stores/reservationStore';
import { ref, computed } from 'vue';
import moment from 'moment';

const store = useReservationStore();
const reservations = computed(() => store.reservations);
const loading = ref(false);
const search = ref('');

const cols = ref([
    { field: 'id', title: '#', isUnique: true, width: '50px' },
    { field: 'name', title: 'Name' },
    { field: 'phone', title: 'Phone' },
    { field: 'email', title: 'Email' },
    { field: 'days', title: 'Days' },
    { field: 'date', title: 'Date' },
    { field: 'location', title: 'Location' },
    // { field: 'created_at', title: 'Created', width: '120px' },
    { field: 'actions', title: '', width: '200px' },
]);

const getReservations = async () => {
    loading.value = true;
    await Reservation.all()
    .then(
        res => {
            if(res.data.status == 200) {
                store.setReservations(res.data.reservations);
            }
        }
    );
    loading.value = false;
}

const getDeffDays = (res) => {
    const start = moment(res.pickup_date, 'YYYY-MM-DD');
    const end = moment(res.return_date, 'YYYY-MM-DD');

    return moment.duration(end.diff(start)).asDays();
}

getReservations();

</script>
<style></style>