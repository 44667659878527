import axios from 'axios'
import { backendUrl } from '@/config/app'
import useAuthStore from '@/stores/authStore';
import router from '@/router';
import authStore from '@/stores/authStore';

const createApiInstance = (contentType) => {
    const instance = axios.create({
        baseURL: backendUrl,
    });

    instance.defaults.withCredentials = true;
    instance.defaults.headers = { 'Content-Type': contentType, 'Accept': 'application/json' };

    instance.interceptors.request.use(config => {
        config.headers.Authorization = 'Bearer ' + useAuthStore().token;
        return config;
    });

    instance.interceptors.response.use(
        resovled => resovled,
        rejected => {
            if(rejected.response?.status == 401) {
                router.push({ name: 'login' });
                authStore().logout();
            }
            return Promise.reject(rejected);
        }
    )

    return instance;
}

const Api = createApiInstance('application/json');
const ApiForm = createApiInstance('multipart/form-data');

export { ApiForm };
export default Api;
